<!--
  PACKAGE_NAME : src/pages/euc/chineseWall
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 2024-06-11
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab :title="$_msgContents('UC.WORD.DEPT_ATTRIBUTES', { defaultValue: '부서 속성' })">
        <DeptAttributes v-if="tabIndex === 0" :key="componentKey" :dept-list="deptList" />
      </Tab>
      <Tab :title="$_msgContents('UC.WORD.BLOCKING_POLICY', { defaultValue: '차단 정책' })">
        <Inception v-if="tabIndex === 1" :key="componentKey" :dept-list="deptList" />
      </Tab>
      <Tab :title="$_msgContents('UC.WORD.EXCEPTION_POLICY', { defaultValue: '예외 정책' })">
        <Exception v-if="tabIndex === 2" :key="componentKey" :dept-list="deptList" />
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import Tab from "@/components/common/tab.vue";
import Tabs from "@/components/common/tabs.vue";
import DeptAttributes from './dept-attributes.vue';
import Inception from './policy/inception.vue';
import Exception from './policy/exception.vue';
import {isSuccess} from "@/plugins/common-lib";

export default {
  components: {
    Tabs,
    Tab,
    DeptAttributes,
    Inception,
    Exception
  },
  data() {
    return {
      componentKey: 0,
      deptList: [],
      tabIndex: 0,
      tabs: {
        selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path] || 0,
      },
    }
  },
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
    /** @description: 부서 데이터 조회 */
    async selectDeptList() {
      const paramsData = { pagesize: 100000 };
      const payload = {
        actionname: 'DEPT_LIST_ALL_VIEW',
        data: { params: paramsData },
        loading: false,
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        this.deptList = res.data.data;
        this.componentKey++;
      }
    },
  },
  async created() {
    await this.selectDeptList();
  }
}
</script>